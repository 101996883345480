<template>
  <div class="pl-3 pr-3">
    <card type="default" header-classes="bg-transparent" v-loading="loader">
      <div class="position-relative">
        <h4 class="d-inline-block edit-patient-card-headings text-uppercase text-gray-400 font-weight-400 mb-4">Basic
          Info</h4>
        <base-button @click="deletePatient(patient.id)"
                     class="position-absolute right-0 red-outline-button pt-2 pb-2 custom-button font-weight-normal"
                     id="edit-user-details-button">
          Remove Patient
        </base-button>
      </div>
      <div class="patient-detail-form-container" id="basic-info-form-container">
        <el-form :model="form" :rules="basicInfoRules" ref="basicInfo" label-position="top" label-width="120px">
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <el-form-item label="First Name" prop="first_name">
                <el-input placeholder="First Name" v-model="form.first_name" @change="basicInfoChanged('first_name')"></el-input>
              </el-form-item>
            </div>
            <div class="col-xs-12 col-md-6">
              <el-form-item label="Last Name" prop="last_name">
                <el-input placeholder="Last Name" v-model="form.last_name" @change="basicInfoChanged('last_name')"></el-input>
              </el-form-item>
            </div>

            <div class="col-xs-12 col-md-6">
              <el-form-item label="Email" prop="email">
                <el-input placeholder="Email" v-model="form.email" @change="basicInfoChanged('email')"></el-input>
              </el-form-item>
            </div>
            <div class="col-xs-12 col-md-6">
              <el-form-item label="Phone" prop="phone">
                <el-input @input="acceptNumber" placeholder="123-456-7890" v-model="form.phone" @change="basicInfoChanged('phone')"></el-input>
              </el-form-item>
            </div>

            <div class="col-xs-12 col-md-6">
              <el-form-item label="Height" prop="hight">
                <div class="row">
                  <div class="col-xs-12 col-md-6">
                    <el-select
                      class="w-100 select-primary pagination-select"
                      v-model="form.feet"
                      @change="setFeet"
                      placeholder="FEET"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in dropdowns.feet"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="col-xs-12 col-md-6">
                    <el-select
                      class="w-100 select-primary pagination-select"
                      v-model="form.inches"
                      @change="setInches"
                      placeholder="INCHES"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in dropdowns.inches"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>

                </div>

              </el-form-item>
            </div>
            <div class="col-xs-12 col-md-6">
              <div>
                <el-form-item label="DOB" prop="dob">
                <b-input-group class="mb-3">
                  <el-input style="height: 40px; width: calc(100% - 60px); border-top-right-radius: 0px;border-bottom-right-radius: 0px"
                                v-mask="'##/##/####'"
                                id="example-input"
                                v-model="form.dob"
                                type="text"
                                placeholder="MM/DD/YYYY"
                                autocomplete="off"
                                name="dob"
                                @blur="handleDob"
                                @change="basicInfoChanged('dob')"
                  ></el-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      min="1930-01-01"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="example-input"
                      :date-format-options="{ month: '2-digit' ,day: '2-digit', year: 'numeric' }"
                      @context="onContext"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
                </el-form-item>
              </div>
            </div>
            <!--              </el-form-item>-->

            <div class="col-xs-12 col-md-6">
              <el-form-item label="Status" prop="status">
                <el-select
                  class="w-100 select-primary pagination-select"
                  v-model="form.status"
                  @change="basicInfoChanged('status')"
                  placeholder="Status"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in dropdowns.status"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-xs-12 col-md-6">
              <el-form-item label="MRN Number (Optional)">
                <el-input :maxlength="16" placeholder="MRN Number" v-model="form.mrn_number" @change="basicInfoChanged('mrn_number')"></el-input>
              </el-form-item>
            </div>

          </div>
        </el-form>
      </div>
    </card>
  </div>
</template>

<script>
import patientConstants from "@/constants/patientConstants";
import pickerOptions from "@/constants/pickerOptions";
import moment from 'moment'
import swal from "sweetalert2";
import Helper from "@/util/globalHelpers";

export default {
  name: "BasicInfoCard",
  props: ['patient', 'loader'],
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        hight: '',
        dob: '',
        status: '',
        mrn_number: '',
        feet: '',
        inches: '',
      },
      basicInfoRules: {
        first_name: [
          {required: true, message: 'Please enter first name', trigger: 'blur'},
        ],
        last_name: [
          {required: true, message: 'Please enter last name', trigger: 'blur'}
        ],
        email: [
          {type: 'email', required: true, message: 'Please enter email', trigger: 'blur'}
        ],
        hight: [
          {required: true, message: 'Please select height', trigger: 'blur'}
        ],
        dob: [
          {required: true, message: 'Please pick a date', trigger: 'blur'}
        ],
        status: [
          {required: true, message: 'Please enter activity form', trigger: 'blur'}
        ]
      },
      dropdowns: {
        status: [
          {
            value: 1,
            label: 'Active'
          }, {
            value: 0,
            label: 'Inactive'
          }
        ],
        feet: patientConstants.feet,
        inches: patientConstants.inches,
      },
      pickerOptions: {
        dobPickerOptions: pickerOptions.dobPickerOptions,
      }
    }
  },
  watch: {
    patient(newVal) {
      this.assignValues(newVal)
    }
  },
  computed: {
    validation() {
      return this.form.dob.length < 11
    }
  },
  methods: {
    /**
     * Accept and format number in 999-999-9999
     */
    acceptNumber() {
      this.form.phone = Helper.formatPhoneNumber(this.form.phone)
    },

    basicInfoChanged(field) {
      this.$emit('patientEdited', this.patient[field] != this.form[field] ? true : false)
    },
    handleDob(e)
    {
      if (moment(e.target.value).format('MM/DD/YYYY') == 'Invalid date'){
        this.form.dob = '';
      }
      if (moment(this.form.dob) < moment('01/01/1930')) {
        this.form.dob = '01/01/1930';
      }
    },
    onContext(ctx) {
      if (ctx.selectedYMD != ''){
        this.form.dob = ctx.selectedFormatted
        this.basicInfoChanged('dob')
      }
    },
    deletePatient(id) {
      swal.fire({
        text: 'Would you like to remove this patient from your program? The patient can still use the app but will no longer be connected to your program.',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: '#F5365C',
        cancelButtonColor: '#8898AA'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.delete(this.$store.getters.getBaseUrl + '/api/patient/' + id)
            .then((response) => {
              this.$store.commit('toggleSessionCheck', true)
              this.$store.commit('updateSessionTime', 0)
              this.$notify.success({
                title: 'Success',
                message: 'Removed successfully'
              });
              // this.$router.push({name: 'ManagePatients'});
              this.isPatientDeleted();
            })
            .catch((error) => console.error(error.response.message))
            .finally(() => this.addPatientLoader = false)
        }
      })
    },
    assignValues(patient) {
      this.form.first_name = patient.first_name;
      this.form.last_name = patient.last_name;
      this.form.email = patient.email;
      this.form.phone = patient.phone;
      this.form.dob = moment(patient.dob).format('MM/DD/YYYY') === 'Invalid date' ?
        '' : moment(patient.dob).format('MM/DD/YYYY')
      // console.log(this.form.dob)
      this.form.status = patient.status;
      this.form.mrn_number = patient.mrn_number;
      this.setHeight(patient.hight);
    },

    setHeight(height) {
      if (!height || height === 'NULL' || height === 'undefined') {
        return this.form.hight = '';
      }
      let heightArr = height.split('.');
      if (typeof heightArr[0] !== 'undefined') {
        this.form.feet = heightArr[0];
      }

      if (typeof heightArr[1] !== 'undefined') {
        this.form.inches = heightArr[1];
      }

      if (height !== 'NULL') {
        this.form.hight = height;
      } else {
        this.form.hight = '';
      }
    },

    setFeet() {
      this.form.hight = this.form.feet + '.' + this.form.inches
      this.basicInfoChanged('hight')
    },

    setInches() {
      this.form.hight = this.form.feet + '.' + this.form.inches
      this.basicInfoChanged('hight')
    },

    isPatientDeleted(){
      this.$emit('isPatientDeleted')
    }
  }
}
</script>

<style scoped>

</style>
